import React from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import AsyncSelect from 'react-select/async'
import { ModelApi } from 'src/api'
import { debounce } from '@material-ui/core'

class AsyncModelSelect extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      options: props.options || [],
      asyncOptionsCache: [],
    }
    this.debouncedLoadOptions = debounce(
      (inputValue, callback) => this._rawLoad(inputValue, callback),
      500
    )
  }

  get composedOptions() {
    const { options, asyncOptionsCache } = this.state
    const valuesIncluded = []
    return [...options, ...asyncOptionsCache].filter((opt) => {
      if (valuesIncluded.includes(opt.value)) {
        return false
      } else {
        valuesIncluded.push(opt.value)
        return true
      }
    })
  }

  get selectedOption() {
    const { isMulti, value } = this.props

    if (isMulti)
      return this.composedOptions.filter(
        (opt) => (value || []).indexOf(opt.value) > -1
      )
    else return this.composedOptions.find((opt) => opt.value === value)
  }

  get defaultOptions() {
    const { value, isMulti } = this.props
    return this.composedOptions.filter(({ value: optValue }) =>
      isMulti ? (value || []).indexOf(optValue) === -1 : optValue !== value
    )
  }

  componentDidMount() {
    this.loadSelectedOptions()
  }

  loadSelectedOptions() {
    const { isMulti, value } = this.props

    const selected = this.selectedOption
    if (value && !isMulti && !selected)
      this._rawLoad(
        '',
        (opts) => {
          this.setState({
            asyncOptionsCache: opts,
          })
        },
        1,
        JSON.stringify({ id: value })
      )
  }

  noOptionsMessage = ({ inputValue }) =>
    !inputValue ? 'Digite para pesquisar' : 'Nenhuma opção encontrada'

  isOptInCache = (opt) =>
    this.composedOptions.some((c) => c.value === opt.value)

  _rawLoad = (inputValue, callback, limit, altModelFilter = null) => {
    const {
      authToken,
      djangoApp,
      djangoModel,
      value,
      modelFilter = '{}',
      additionalFilters = null,
    } = this.props
    this.setState({ isLoading: true })
    return ModelApi.shared
      .listSelectOptions(
        authToken,
        djangoApp,
        djangoModel,
        (inputValue || '').toLowerCase(),
        value,
        limit,
        altModelFilter || modelFilter,
        additionalFilters
      )
      .then(({ data }) => callback(data.data))
      .finally(() => this.setState({ isLoading: false }))
  }

  loadOptions = (inputValue) =>
    new Promise((resolve) => this.debouncedLoadOptions(inputValue, resolve))

  forceUpdate() {
    this._rawLoad(
      '',
      (opts) => {
        this.setState({
          asyncOptionsCache: opts,
        })
      },
      999
    )
  }

  sendOptionToCache = (opt) => {
    if (!opt) return

    const { isMulti } = this.props
    const { asyncOptionsCache } = this.state

    if (isMulti) {
      this.setState({
        asyncOptionsCache: [
          ...asyncOptionsCache,
          ...opt.filter((o) => !this.isOptInCache(o)),
        ],
      })
    } else if (!this.isOptInCache(opt)) {
      this.setState({
        asyncOptionsCache: [...asyncOptionsCache, opt],
      })
    }
  }

  handleChange = (opt) => {
    const { onChange } = this.props

    onChange && onChange(opt)
    this.sendOptionToCache(opt)
  }

  render() {
    const {
      id,
      placeholder,
      value,
      fieldName,
      isDisabled,
      styles,
      isMulti,
      menuPlacement,
      isClearable,
    } = this.props
    return (
      <AsyncSelect
        id={id}
        className="select__basic-single"
        classNamePrefix="select"
        placeholder={placeholder || 'Selecione uma opção'}
        noOptionsMessage={this.noOptionsMessage}
        loadingMessage={() => 'Carregando...'}
        value={value ? this.selectedOption : undefined}
        defaultOptions={this.defaultOptions}
        isSearchable={true}
        name={fieldName}
        isDisabled={isDisabled}
        loadOptions={this.loadOptions}
        onChange={this.handleChange}
        styles={styles}
        isMulti={isMulti}
        closeMenuOnSelect={!isMulti}
        menuPlacement={menuPlacement}
        isClearable={isClearable}
      />
    )
  }
}

class CachedModelSelect extends AsyncModelSelect {
  componentDidMount() {
    this.forceUpdate()
  }

  render() {
    const { isLoading } = this.state
    const {
      id,
      placeholder,
      value,
      fieldName,
      isDisabled,
      styles,
      isMulti,
      menuPlacement,
      isClearable,
    } = this.props
    return (
      <Select
        isSearchable
        id={id}
        className="select__basic-single"
        classNamePrefix="select"
        placeholder={
          isLoading ? 'Carregando...' : placeholder || 'Selecione uma opção'
        }
        noOptionsMessage={this.noOptionsMessage}
        loadingMessage={() => 'Carregando...'}
        value={value ? this.selectedOption : undefined}
        options={this.defaultOptions.filter((opt) => opt.value !== value)}
        name={fieldName}
        isDisabled={isDisabled || isLoading}
        onChange={this.handleChange}
        styles={styles}
        isMulti={isMulti}
        closeMenuOnSelect={!isMulti}
        menuPlacement={menuPlacement}
        isClearable={isClearable}
      />
    )
  }
}

export const isModelSelectCached = (djangoModel) =>
  ['sector', 'equipment', 'provider', 'item'].indexOf(djangoModel) === -1

const ModelSelect = React.forwardRef((props, ref) => {
  // TODO: Implement remote config
  const shouldCache = isModelSelectCached(props.djangoModel)

  if (shouldCache) {
    return <CachedModelSelect ref={ref} {...props} />
  } else {
    return <AsyncModelSelect ref={ref} {...props} />
  }
})

ModelSelect.propTypes = {
  authToken: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired,
  djangoApp: PropTypes.string.isRequired,
  djangoModel: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      label: PropTypes.string.isRequired,
      color: PropTypes.string,
    })
  ),
  value: PropTypes.any,
  onChange: PropTypes.func,
  isDisabled: PropTypes.bool,
  isMulti: PropTypes.bool,
  styles: PropTypes.object,
  id: PropTypes.string,
}

export default ModelSelect
