import * as types from './types'

const reducer = (state = { isOpen: false }, { type, payload }) => {
  switch (type) {
    case types.SHOW:
      if (window.fcWidget) window.fcWidget.hide()
      if (state.isOpen) payload.prevForm = state
      return {
        ...payload,
        isOpen: true,
      }

    case types.DISMISS:
      if (window.fcWidget) window.fcWidget.show()
      return { prevForm: state.prevForm, isOpen: false }

    case types.SHOW_PREVIOUS:
      if (window.fcWidget) window.fcWidget.hide()
      if (state.prevForm) return { ...state.prevForm, isOpen: true }
      else console.warn('No previous form available')
      return state

    case types.PERSIST:
      return {
        ...state,
        props: {
          ...(state.props || {}),
          data: payload,
        },
      }

    default:
      return state
  }
}

export default reducer
