import * as types from './types'

/**
 * Shows an django form modal
 *
 * @param {number} objectId Django object id
 * @param {string} djangoApp Django app name
 * @param {string} djangoModel Django model name
 * @param {object} fixedData Fixed data for filling the form
 * @param {function} onSubmit Callback for when the form is submited
 * @param {function} onClose Callback for when the form is closed
 */
export const show = (
  objectId,
  djangoApp,
  djangoModel,
  data,
  fixedData,
  onSubmit,
  onClose,
  modalProps,
  requestParams
) => ({
  type: types.SHOW,
  payload: {
    props: {
      objectId,
      djangoApp,
      djangoModel,
      data,
      fixedData,
      onSubmit,
      onClose,
      modalProps,
      requestParams,
    },
  },
})

export const dismiss = () => ({
  type: types.DISMISS,
})

export const showPrevious = () => ({
  type: types.SHOW_PREVIOUS,
})

export const persistData = (data) => ({
  type: types.PERSIST,
  payload: data,
})
