import React, { useCallback, useEffect, useState } from 'react'
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CDataTable,
  CInput,
  CInputGroup,
  CInputGroupAppend,
  CLink,
  CRow,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import HexColorLabel from './HexColorLabel'
import { ModelApi } from 'src/api'
import Paginator from 'src/components/Paginator'
import { ActionButton } from 'src/views/buttons'
import TableFieldsSelector from './TableFieldsSelector'
import PermissionsMiddleware from 'src/reusable/PermissionsMiddleware'
import ReportApi from 'src/api/reportApi'
import { emptySafe, statusColumn } from './DjangoListColumnParsers'
import { DjangoFormModal } from './DjangoForm'

import NoDataImage from '../assets/no-data.svg'

import './DjangoList.scss'
import memoizee from 'memoizee'
import { useToken } from 'src/state/ducks/auth/hooks'
import { useIsLoading, useLoader } from 'src/state/ducks/loader/hooks'
import { useModal } from 'src/state/ducks/modal/hooks'
import { useToast } from 'src/state/ducks/toast/hooks'
import { useModalForm } from 'src/state/ducks/modalForm/hooks'
import { useHistory } from 'react-router-dom'
import useDocumentationModal from './useDocumentationModal'

export const isMobile = {
  Android: memoizee(() => navigator.userAgent.match(/Android/i)),
  BlackBerry: memoizee(() => navigator.userAgent.match(/BlackBerry/i)),
  iOS: memoizee(() => navigator.userAgent.match(/iPhone|iPad|iPod/i)),
  Opera: memoizee(() => navigator.userAgent.match(/Opera Mini/i)),
  Windows: memoizee(
    () =>
      navigator.userAgent.match(/IEMobile/i) ||
      navigator.userAgent.match(/WPDesktop/i)
  ),
  any: memoizee(
    () =>
      // TODO: Consider adding small resolutions on this too
      isMobile.Android() ||
      isMobile.BlackBerry() ||
      isMobile.iOS() ||
      isMobile.Opera() ||
      isMobile.Windows()
  ),
}

export const EmptyTableSlot = ({
  text = 'Sem resultados',
  showIllustration = false,
}) => (
  <div style={{ textAlign: 'center', opacity: '.5' }}>
    {showIllustration ? (
      <img
        src={NoDataImage}
        alt="no data"
        style={{ height: '6rem', margin: '1rem auto', display: 'block' }}
      />
    ) : (
      <CIcon name="cil-list" style={{ marginRight: '.2rem' }} />
    )}
    {text}
  </div>
)

export const parseReportFilterForm = (data, form) => {
  const dataForRequest = {}
  delete data.company
  Object.entries(data).forEach(([key, val]) => {
    if (!val) delete data[key]
    if (typeof val === 'boolean') data[key] = val ? 'True' : 'False'
  })
  Object.entries(form.fields).forEach(([key, val]) => {
    let value = data[key]
    if (!value) return
    if (val.model_name) dataForRequest[`${key}_id`] = value
    else dataForRequest[key] = value
  })
  return dataForRequest
}

export const FormButton = ({
  title,
  iconName,
  objectId,
  copyId,
  djangoApp,
  djangoModel,
  formRoute,
  onReload,
  formsAsModal,
  style,
  reloadOnClose = true,
}) => {
  const token = useToken()
  const showModalForm = useModalForm()
  const history = useHistory()

  if (formRoute && !formsAsModal)
    return (
      <ActionButton
        label={title}
        onClick={() => history.push(formRoute, { id: objectId })}
        iconName={iconName}
      />
    )
  else
    return (
      <>
        <CLink
          color="primary"
          onClick={async () => {
            const {
              data: { form = {} },
            } = await ModelApi.shared.form(
              token,
              djangoApp,
              djangoModel,
              copyId
            )
            const copyData = {
              ...(form.data || {}),
              id: undefined,
              custom_identifier: undefined,
            }

            showModalForm(
              objectId,
              djangoApp,
              djangoModel,
              copyData || {},
              {},
              onReload ? onReload : () => window.location.reload(),
              reloadOnClose
                ? onReload
                  ? onReload
                  : () => window.location.reload()
                : null
            )
          }}
          style={style}
        >
          <CIcon name={iconName} style={{ marginRight: '5px' }} />
          {!isMobile.any() && title}
        </CLink>
      </>
    )
}

const DjangoList = ({
  djangoApp,
  djangoModel,
  formsAsModal,
  fields: propsFields = [],
  scopedSlots: propsScopedSlots = {},
  header: propsHeader = {},
  onReload,
  onDeletion,
  defaultSort,
  aditionalActions: AditionalActions = null,
  cerateButtonLabel = 'Novo',
  showFilterButton = false,
  defaultFilters = {},
  documentationPath = null,
}) => {
  const loadingAction = `prevenX/list/${djangoModel}`
  const formRoute = `/${djangoApp}/${djangoModel}/form`

  const token = useToken()
  const [startLoading, stopLoading] = useLoader()
  const showModal = useModal()
  const showToast = useToast()
  const showDocs = useDocumentationModal()
  const isLoading = useIsLoading(loadingAction)

  const [data, setData] = useState(null)
  const [title, setTitle] = useState(null)
  const [header, setHeader] = useState(propsHeader)
  const [createPath, setCreatePath] = useState(null)
  const [updatePath, setUpdatePath] = useState(null)
  const [deletePath, setDeletePath] = useState(null)
  const [page, setPage] = useState(1)
  const [paginator, setPaginator] = useState(null)
  const [order, setOrder] = useState(defaultSort || undefined)
  const getSessionFilters = () => {
    try {
      return JSON.parse(sessionStorage.getItem(djangoModel))
    } catch (e) {
      return null
    }
  }
  const sessionFilters = getSessionFilters()
  const [textFilter, setTextFilter] = useState(
    sessionFilters?.textFilter || null
  )
  const [customFields, setCustomFields] = useState(null)
  const [djangoReportFilterCallback, setDjangoReportFilterCallback] =
    useState(null)
  const [djangoReportFilterForm, setDjangoReportFilterForm] = useState(null)
  const [listFilters, setListFilters] = useState(
    sessionFilters?.listFilters || null
  )

  const setSessionFiltersKey = (key, data) => {
    const sessionFilters = getSessionFilters()
    sessionStorage.setItem(
      djangoModel,
      JSON.stringify({
        ...(sessionFilters || {}),
        [key]: data,
      })
    )
  }

  const handleReportFilter = useCallback(
    async (djangoApp, reportPath) => {
      const { data } = await ReportApi.shared.getReportForm(
        token,
        djangoApp,
        reportPath
      )

      if (data?.form?.ordered_fields?.length > 0)
        setDjangoReportFilterForm(data.form)
      else return { data: {} }

      return await new Promise((resolve, reject) =>
        setDjangoReportFilterCallback([resolve, reject])
      )
    },
    [token, setDjangoReportFilterForm]
  )

  let fields = [],
    scopedSlots = {
      status: statusColumn,
      urgency_level_color: ({ urgency_level_color }) => (
        <td>
          <HexColorLabel hexCode={urgency_level_color} />
        </td>
      ),
      actions: ({ id }) => (
        <td align="right" style={{ whiteSpace: 'nowrap' }}>
          {!!createPath && (
            <PermissionsMiddleware
              djangoApp={djangoApp}
              djangoModel={djangoModel}
              permissionType="can_change"
            >
              <FormButton
                formsAsModal={true}
                copyId={id}
                title="Copiar"
                iconName="cil-copy"
                djangoApp={djangoApp}
                djangoModel={djangoModel}
                onReload={() => (onReload ? onReload(loadData) : loadData())}
              />
            </PermissionsMiddleware>
          )}
          {!!updatePath && (
            <PermissionsMiddleware
              djangoApp={djangoApp}
              djangoModel={djangoModel}
              permissionType="can_change"
            >
              <FormButton
                formsAsModal={formsAsModal}
                objectId={id}
                title="Editar"
                iconName="cil-pencil"
                djangoApp={djangoApp}
                djangoModel={djangoModel}
                onReload={() => onReload && onReload(loadData)}
                formRoute={formRoute}
              />
            </PermissionsMiddleware>
          )}
          {!!deletePath && (
            <PermissionsMiddleware
              djangoApp={djangoApp}
              djangoModel={djangoModel}
              permissionType="can_delete"
            >
              <ActionButton
                label="Excluir"
                onClick={() => {
                  showModal({
                    onConfirm: () => handleDeletion(id),
                    title: 'Deseja mesmo excluir o registro?',
                    body: 'Esta ação não poderá ser desfeita',
                    doubleClickToConfirm: true,
                  })
                }}
                iconName="cil-trash"
              />
            </PermissionsMiddleware>
          )}
        </td>
      ),
    }
  if (propsFields.length !== undefined) {
    fields = propsFields
    scopedSlots = {
      ...scopedSlots,
      ...propsScopedSlots,
    }
  } else {
    fields = Object.keys(propsFields)
    fields.forEach((key) => {
      if (propsFields[key]) scopedSlots[key] = propsFields[key]
      else if (!scopedSlots[key])
        scopedSlots[key] = (row) => emptySafe(row[key])
    })
  }
  if (customFields)
    (customFields || []).forEach((key) => {
      if (!propsFields[key] && !scopedSlots[key])
        scopedSlots[key] = (row) => emptySafe(row[key])
    })

  const filteredFields = [
    ...(customFields || fields).filter((field) => field !== 'actions'),
    {
      key: 'actions',
      _style: { width: '10rem', textAlign: 'right' },
    },
  ]

  const loadData = (inputOrder, dumpCache = false) => {
    if (dumpCache) {
      ModelApi.shared._clearMemos()
    }
    ModelApi.shared
      .list(
        token,
        djangoApp,
        djangoModel,
        {
          ...((!listFilters && !textFilter && defaultFilters) || {}),
          ...((!!listFilters && listFilters[0]) || {}),
        },
        page,
        10,
        inputOrder || order,
        textFilter
      )
      .then(
        ({ data = {} }) => {
          setData(data.data)
          setTitle(data.title)
          setHeader({
            ...data.header,
            ...header,
          })
          setCreatePath(data.create_path)
          setUpdatePath(data.update_path)
          setDeletePath(data.delete_path)
          setPaginator(data.paginator)
          setPage((data.paginator || {}).current_page_number)
        },
        (e) => {
          console.error(e)
          setData([])
          showToast('Erro ao carregar os registros.', { appearance: 'error' })
        }
      )
  }

  const handleDeletion = (id) => {
    if (onDeletion) onDeletion(id)
    else {
      startLoading(loadingAction)

      ModelApi.shared
        .delete(token, djangoApp, djangoModel, id)
        .then(
          () => {
            loadData()
            showToast('Sucesso ao deletar registro.', {
              appearance: 'success',
            })
          },
          (e) => {
            console.error(e)
            showToast('Erro ao deletar registro.', { appearance: 'error' })
          }
        )
        .finally(() => stopLoading(loadingAction))
    }
  }

  const capitalizedHeader = { ...header }
  Object.entries(capitalizedHeader).forEach(([key, value]) => {
    capitalizedHeader[key] = (value || '').toTitleCase()
  })

  useEffect(() => {
    if (
      ((!data && djangoModel && djangoApp) ||
        ((paginator || {}).current_page_number &&
          page !== (paginator || {}).current_page_number)) &&
      !isLoading
    )
      loadData()

    return () => null
    // eslint-disable-next-line
  }, [paginator, page, djangoModel, djangoApp])

  useEffect(loadData, [listFilters])

  return (
    <CCard
      className="django-list"
      style={{
        margin: '0 -15px', // Compensate default layout padding
      }}
    >
      <CCardHeader>
        {title && title.toTitleCase()}
        <div className="card-header-actions">
          {AditionalActions && (
            <AditionalActions
              listFilters={listFilters?.[0]}
              loadData={loadData}
            />
          )}
          {documentationPath && (
            <ActionButton
              label="Ajuda"
              iconName="cil-file"
              fullWidth
              color="primary"
              variant="outlined"
              className="mt-3"
              onClick={() => showDocs(documentationPath)}
            />
          )}
          {!!createPath && (
            <PermissionsMiddleware
              djangoApp={djangoApp}
              djangoModel={djangoModel}
              permissionType="can_add"
            >
              {isMobile.any() ? (
                <ActionButton
                  label="Exportar"
                  iconName="cil-file"
                  fullWidth
                  color="primary"
                  variant="outlined"
                  className="mt-3"
                  onClick={() =>
                    handleReportFilter(djangoApp, djangoModel)
                      .then(({ data }) =>
                        ReportApi.shared._fileDownload(
                          token,
                          ReportApi.shared.getReportUrl(
                            djangoApp,
                            djangoModel,
                            {
                              ...data,
                              company: ReportApi.shared.companyHeader,
                            },
                            'pdf'
                          ),
                          'teste.pdf'
                        )
                      )
                      .then(
                        () =>
                          showToast('Arquivo baixado', {
                            appearance: 'success',
                          }),
                        () =>
                          showToast('Erro ao baixar o arquivo', {
                            appearance: 'error',
                          })
                      )
                  }
                >
                  Imprimir PDF
                </ActionButton>
              ) : (
                <ActionButton
                  label="Exportar"
                  iconName="cil-file"
                  onClick={() =>
                    handleReportFilter(djangoApp, djangoModel).then(
                      ({ data }) =>
                        ReportApi.shared.openReportUrl(
                          token,
                          djangoApp,
                          djangoModel,
                          {
                            ...data,
                            company: ReportApi.shared.companyHeader,
                          }
                        ),
                      () => null
                    )
                  }
                />
              )}

              <FormButton
                formsAsModal={formsAsModal}
                title={cerateButtonLabel}
                iconName="cil-pencil"
                djangoApp={djangoApp}
                djangoModel={djangoModel}
                onReload={() => onReload && onReload(loadData)}
                formRoute={formRoute}
                style={{ marginLeft: '.7rem' }}
              />
            </PermissionsMiddleware>
          )}
        </div>
      </CCardHeader>
      <CCardBody>
        <CRow style={{ marginBottom: '1rem' }}>
          <CCol md="6">
            <CInputGroup>
              <CInput
                placeholder="Digite um termo para pesquisar"
                value={textFilter || ''}
                onChange={(e) => setTextFilter(e.target.value)}
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    setSessionFiltersKey('textFilter', event.target.value)
                    loadData()
                  }
                }}
              />
              <CInputGroupAppend>
                <CButton
                  color="primary"
                  onClick={() => {
                    loadData()
                    setSessionFiltersKey('textFilter', textFilter)
                  }}
                >
                  Pesquisar
                </CButton>
              </CInputGroupAppend>
            </CInputGroup>
          </CCol>
          <CCol
            md={3}
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            {showFilterButton && (
              <CButton
                size="sm"
                color="primary"
                variant="outline"
                block={isMobile.any()}
                style={{ margin: isMobile.any() ? '1rem' : '' }}
                onClick={() => {
                  handleReportFilter(djangoApp, djangoModel).then(
                    ({ data, form }) => {
                      const dataForRequest = parseReportFilterForm(data, form)
                      setSessionFiltersKey('listFilters', [
                        dataForRequest,
                        data,
                      ])
                      setListFilters([dataForRequest, data])
                    },
                    () => null
                  )
                }}
              >
                <CIcon name="cil-filter" />
                &nbsp; Filtrar
              </CButton>
            )}
            &nbsp;
            {listFilters && (
              <CButton
                size="sm"
                color="dark"
                variant="ghost"
                onClick={() => setListFilters(null)}
              >
                Limpar filtros
              </CButton>
            )}
          </CCol>
          <CCol md={3}>
            {!isMobile.any() && (
              <TableFieldsSelector
                onRowChange={(rows) => setCustomFields(rows)}
                defaultFields={fields}
                fields={header}
                djangoModel={djangoModel}
                djangoApp={djangoApp}
              />
            )}
          </CCol>
        </CRow>
        <CDataTable
          hover
          striped
          bordered
          size="sm"
          items={data}
          responsive={false}
          header={!isMobile.any()}
          fields={filteredFields}
          columnHeaderSlot={{
            ...capitalizedHeader,
            actions: 'Ações',
            last_login: 'Último login',
          }}
          noItemsViewSlot={<EmptyTableSlot />}
          sorter={{
            external: true,
          }}
          sorterValue={
            order && {
              column: (order || '').replace('-', ''),
              asc: order && order[0] !== '-',
            }
          }
          onSorterValueChange={({ column, asc }) => {
            if (column) {
              const identifier = (asc ? '' : '-') + column
              if (identifier !== order) {
                setOrder(identifier)
                loadData(identifier)
              }
            }
          }}
          scopedSlots={{
            ...scopedSlots,
          }}
        />
        {!!(paginator || {}).current_page_number && (
          <Paginator
            pageNumber={(paginator || {}).current_page_number || 1}
            lastPage={(paginator || {}).last_page_number || 1}
            onChange={setPage}
          />
        )}
      </CCardBody>
      {djangoReportFilterForm && (
        <DjangoFormModal
          showRelatedFieldsActions={false}
          form={djangoReportFilterForm}
          submit={false}
          // TODO: Remove text filter
          data={listFilters && listFilters[1]}
          onClose={() => {
            setDjangoReportFilterForm(null)
            djangoReportFilterCallback && djangoReportFilterCallback[1]()
          }}
          onSubmit={(data) => {
            Object.entries(data).forEach(([key, value]) => {
              if (value === undefined || value === null) delete data[key]
            })
            djangoReportFilterCallback &&
              djangoReportFilterCallback[0]({
                data: {
                  ...data,
                  company: ReportApi.shared.companyHeader,
                },
                form: { ...djangoReportFilterForm },
              })
            setDjangoReportFilterForm(null)
          }}
        />
      )}
    </CCard>
  )
}

export default DjangoList
